import React from "react";
import { ReactComponent as FacebookIcon } from "../image/svgs/facebook-icon.svg";
import { ReactComponent as InstaIcon } from "../image/svgs/icons8-instagram.svg";
import { ReactComponent as GithubIcon } from "../image/svgs/icons8-github.svg";
import { ReactComponent as MediumIcon } from "../image/svgs/icons8-medium.svg";
import { ReactComponent as Wave } from "../image/svgs/wave.svg";

export default function AboutSection() {
  return (
    <>
      <Wave className="rev-wave" />
      <section className="about-section" id="about-section">
        <div className="about-section-container">
          <div className="about-content">
            <div className="about-title">
              <div className="me bg"></div>
              <h1>&copy; Niraj karki</h1>
              <div className="about-icons-container">
                <a
                  href="https://www.facebook.com/ZeusKGames"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon className="about-icons" title="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/zeuskenneth/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstaIcon className="about-icons" title="instagram" />
                </a>
                <a
                  href="https://github.com/zeusk10"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GithubIcon className="about-icons" title="github" />
                </a>
                <a
                  href="https://zeusk10.medium.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MediumIcon className="about-icons" title="medium" />
                </a>
              </div>
            </div>
            <div>
              <div className="about-section-title-container">
                <h1 className="about-section-title">About me</h1>
              </div>
                <p className="medium-font">
                    Experienced Unity game developer with a profound passion for
                    game development. I am dedicated to continual learning and
                    innovation, constantly seeking to integrate new concepts into
                    projects to push creative boundaries. I excel within collaborative
                    teams, leveraging shared experiences to refine and enhance
                    our development capabilities.
                </p>
                <p className="medium-font">
                    Since childhood, my interests have revolved around gaming, sports,
                    graphics, and programming-related activities. I find fulfillment
                    in exploring the dynamic worlds of digital artistry and game
                    development. From crafting intricate wire structures to delving
                    into the complexities of code, I am driven by a passion for creating
                    immersive experiences. My dedication extends to staying updated on
                    the latest advancements in graphics technology and honing my skills
                    in programming languages. With a keen eye for detail and a love for
                    innovation, I am committed to pushing the boundaries of what is
                    possible in the realms of gaming and digital media.
                </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
